import React, { Component } from "react";
import styled from "styled-components";
import { Divider, Layout, Menu, Spin } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import BrandLogo from "../assets/images/logo.png";
import { middlewareVehicle } from "./global";

const { Sider, Content, Header } = Layout;

class CustomLayout extends Component {
  state = {
    collapsed: false,
    isBroke: false,
    render: false,
  };

  componentWillMount() {
    var accessToken = localStorage.getItem("accessToken");

    if (accessToken === undefined || accessToken === null) {
      window.location.pathname = "/login";
    } else {
      this.setState({ render: true });
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { collapsed, isBroke, render } = this.state;

    const isVehicle = middlewareVehicle();

    return (
      <>
        {render ? (
          <Layout
            style={{
              minHeight: window.innerHeight,
            }}
          >
            <Sider
              className="scrollbar-hidden"
              collapsible
              collapsed={collapsed}
              onCollapse={this.toggle}
              style={{
                overflow: "auto",
                height: "100%",
                position: "fixed",
                left: 0,
              }}
              //
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                this.setState({ isBroke: broken });
                // console.log("func", broken);
                // console.log("state", this.state.isBroke);
              }}
            >
              <center>
                <CustomImage src={BrandLogo} alt="Helpp-Life" />
                {isVehicle && (
                  <p style={{ color: "white", fontSize: 12, margin: 0 }}>
                    Véhicule {localStorage.getItem("_vhc")}
                  </p>
                )}
              </center>
              <Divider
                style={{
                  margin: 0,
                  marginBottom: "20px",
                }}
              />

              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[this.props.selectedKey ?? "0"]}
                defaultOpenKeys={[this.props.openKey ?? "sub0"]}
                style={{ height: "100%", borderRight: 0, fontSize: "16px" }}
              >
                <Menu.Item key="warnings" icon={<WarningOutlined />}>
                  <Link to="/warnings">Alertes en cours</Link>
                </Menu.Item>

                {!isVehicle && (
                  <Menu.Item
                    key="users"
                    icon={<UserOutlined />}
                    disabled={isVehicle}
                  >
                    <Link to="/users">Utilisateurs</Link>
                  </Menu.Item>
                )}

                <Divider />
                <Menu.Item
                  style={{ color: "red" }}
                  key="logout"
                  icon={<LogoutOutlined />}
                >
                  <Link
                    to="/login"
                    style={{ color: "red" }}
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("_vhc");
                    }}
                  >
                    Déconnexion
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout
              style={{
                height: "100%",
                marginLeft: this.state.collapsed ? 0 : 200,
                transition: "all 0.2s ease-in-out",
              }}
            >
              {isBroke && (
                <Header>
                  <Menu
                    inlineIndent={0}
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={[this.props.selectedKey ?? "0"]}
                  >
                    <Menu.Item key="warnings">
                      <Link to="/warnings">
                        <WarningOutlined />
                      </Link>
                    </Menu.Item>

                    {!isVehicle && (
                      <Menu.Item key="users">
                        <Link to="/users">
                          <UserOutlined />
                        </Link>
                      </Menu.Item>
                    )}

                    <Menu.Item style={{ color: "red" }} key="logout">
                      <Link
                        to="/login"
                        style={{ color: "red" }}
                        onClick={() => {
                          localStorage.removeItem("accessToken");
                          localStorage.removeItem("_vhc");
                        }}
                      >
                        <LogoutOutlined />
                      </Link>
                    </Menu.Item>

                    {isVehicle && (
                      <Menu.Item>
                        <p
                          style={{
                            color: "white",
                            fontSize: 12,
                            margin: 0,
                          }}
                        >
                          Véhicule {localStorage.getItem("_vhc")}
                        </p>
                      </Menu.Item>
                    )}
                  </Menu>
                </Header>
              )}
              <Content
                style={{
                  margin: 0,
                  padding: 30,
                  minHeight: window.innerHeight,
                  maxHeight: "100%",
                }}
              >
                <ChildWrapper>{this.props.children}</ChildWrapper>
              </Content>
            </Layout>
          </Layout>
        ) : (
          <SpinWrapper>
            <Spin
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </SpinWrapper>
        )}
      </>
    );
  }
}

export default CustomLayout;

const SpinWrapper = styled.div`
  position: relative;
  height: ${window.innerHeight}px;
`;

const CustomImage = styled.img`
  height: 50px;
  margin: 20px auto;
  border-radius: 10px;
`;

const ChildWrapper = styled.div`
  max-height: 100%;
  padding-bottom: 50px;
  h1 {
    font-size: 36px;
    font-weight: 800;
    color: ${(props) => (props.darkMode ? "#fff" : "#000")} !important;
  }
  h2 {
    font-size: 28px;
    font-weight: 700;
    color: ${(props) => (props.darkMode ? "#fff" : "#000")} !important;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => (props.darkMode ? "#fff" : "#000")} !important;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    color: ${(props) => (props.darkMode ? "white" : "black")};
  }
`;
