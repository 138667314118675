import axios from "axios";
import {API_PUBLIC_URL, API_URL} from "../config";
import { catchError } from "./response";

const getApiRequestService = (publicApi = false) => {
  return axios.create({
    baseURL: publicApi ? `${API_PUBLIC_URL}` : `${API_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

// USERS REQUEST...
export const getAllUsers = async (keywords: any) => {
  try {
    const res = await getApiRequestService().get(`/users`);

    return res.data;
  } catch (error) {
    console.log("GET_ALL_USERS", error);

    return catchError(error);
  }
};

export const getUser = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/users/${id}`);

    return res.data;
  } catch (error) {
    console.log("GET_USER", error);

    return catchError(error);
  }
};

export const getUserBalise = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/users/${id}/balise`);

    return res.data;
  } catch (error) {
    console.log("GET_USER_BALISE", error);

    return catchError(error);
  }
};

export const searchUsers = async (value: String) => {
  try {
    const res = await getApiRequestService().get(
      `/users/search?value=${value}`
    );

    return res.data;
  } catch (error) {
    console.log("SEARCH_USERS", error);

    return catchError(error);
  }
};

export const getScannedTag = async (id: string) => {
  try {
    const res = await getApiRequestService(true).get(`/scan-tag/${id}`);
    return res.data;
  } catch (error) {
    console.log("GET_SCANNED_TAG", error);

    // return catchError(error);
    console.log(error);
  }
}

// MEDICAL FOLDER...
export const getMedicalFolder = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/dossier-medical/${id}`);

    return res.data;
  } catch (error) {
    console.log("GET_MEDICAL_FOLDER", error);

    // return catchError(error);
    console.log(error);
  }
};

export const getMedicalFolderPhoto = async (id: any, index: number) => {
  try {
    const res = await getApiRequestService().get(
      `/dossier-medical/${id}/photo/${index}`,
      { responseType: "blob" }
    );

    return URL.createObjectURL(res.data);
  } catch (error) {
    console.log("GET_MEDICAL_FOLDER_PHOTO", error);

    return catchError(error);
  }
};
