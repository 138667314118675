import React, { Component } from "react";
import {
  Divider,
  Button,
  Row,
  Card,
  Col,
  Image,
  Table,
  Modal,
  Spin,
  Checkbox,
  message,
  Input,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import {
  middlewareService,
  middlewareVehicle,
  renderAddress,
  Spacer,
} from "../../components/global";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  getBalise,
  getBaliseUser,
  unsetBaliseAlert,
  unsetBaliseGeoAlert,
  updateBalise,
} from "../../services/balises.services";
import Geocode from "react-geocode";
import { getMedicalFolderPhoto, getUser } from "../../services/users.services";
import Moment from "react-moment";
import {
  addVehicle,
  getBalisesVehicles,
  removeVehicle,
  searchVehicles,
} from "../../services/vehicules.services";
import { GOOGLE_API_KEY } from "../../config";
import GoogleMapReact from "google-map-react";

const { confirm } = Modal;

class DetailBalise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      balise_id: "",
      isGeo: false,
      user_images: [],
      imageLoaded: false,
      vehicules: [],
      selected_service: [],
      search_vehicules: [],
      showSearchVehicles: false,
      Marker: {
        name: "Current position",
        position: {
          lat: 0,
          lng: 0,
        },
      },
    };

    this.disableBalise = this.disableBalise.bind(this);
    this.renderMarker = this.renderMarker.bind(this);
  }

  componentDidMount() {
    this.fetchBalise();
    window.scrollTo(0, 0);

    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyBPG5mwyzJa8XSsd323RdpcB4th47aArrk");
    // set response language. Defaults to english.
    Geocode.setLanguage("en");
  }

  getCoord(address) {
    Geocode.fromAddress(address).then(
      (response) => {
        var location = response.results[0].geometry.location;
        var Marker = this.state.Marker;
        Marker.position = location;
        this.setState({ Marker }, () => console.log(this.state.Marker));
      },
      (error) => {
        console.error(error);
      }
    );
  }

  fetchBalise() {
    // Getting balise informations
    getBalise(this.props.match.params.id).then((res) => {
      console.log("balise info:", res);

      // Get vehicles
      this.fetchVehicles(res.balise.id);

      // Get User who activated informations
      getUser(res?.balise?.id_user_for_who).then((res) => {
        // console.log("user infos", res);
        this.setState({ activating_user: res?.user });
      });

      // Setting state to get balise informations
      this.setState(
        {
          balise: res?.balise,
          isGeo: !res?.balise?.appkey?.length > 0,
        },
        () => {
          var Marker = this.state.Marker;
          Marker.position = {
            lat: this.state.balise?.lat ?? 43,
            lng: this.state.balise?.lon ?? 5,
          };
          this.setState({ Marker }, () => {
            if (
              this.state.Marker.position.lat === 0 &&
              this.state.Marker.position.lng === 0
            ) {
              const adresse = renderAddress(this.state.balise.adresse);
              this.getCoord(adresse);
            }
          });
        }
      );

      // Getting Medical Folder pictures
      var tmp_user_images = this.state.user_images;
      for (var i = 1; i < 4; i++) {
        getMedicalFolderPhoto(res?.balise?.id_user_for_who, i).then((res) => {
          // console.log("photo", res);
          tmp_user_images.push(res);
          this.setState({ user_images: tmp_user_images, imageLoaded: true });
        });
      }
      this.setState({ user_images: tmp_user_images, imageLoaded: true });
    });

    // Getting balise associated users
    getBaliseUser(this.props.match.params.id).then((res) => {
      // console.log("linked_users", res?.users);
      this.setState({ linked_users: res?.users });
    });
  }

  fetchVehicles = async (balise_id) => {
    getBalisesVehicles(balise_id).then(({ vehicules }) =>
      this.setState({ vehicules })
    );
  };

  disableBalise() {
    const _id = this.props.match.params.id;
    const onOk = () => {
      if (this.state.balise?.appkey?.length)
        unsetBaliseAlert(_id).then(() => {
          this.props.history.push("/warnings");
          message.success("Balise désactivée avec succès");
        });
      else
        unsetBaliseGeoAlert(_id).then(() => {
          this.props.history.push("/warnings");
          message.success("Balise (geo) désactivée avec succès");
        });
    };

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <p>Êtes vous sûr de vouloir désactiver la balise?</p>,
      cancelText: "Annuler",
      okText: "Confirmer",
      onOk,
      onCancel() {
        console.log("canceled");
      },
    });
  }

  handleEditServices() {
    const _id = this.props.match.params.id;
    const onOk = () => {
      updateBalise(_id, {
        id: _id,
        selected_service: this.state.selected_service,
      }).then((res) => {
        if (res.balise) {
          message.success("Balise mise à jour avec succès");
          this.fetchBalise();
        }
      });
    };

    confirm({
      title: <h3 style={{ margin: 0 }}>Affectation des services</h3>,
      icon: <span></span>,
      cancelText: "Annuler",
      okText: "Confirmer",
      content: (
        <div>
          <Divider />
          <Checkbox.Group
            options={["15", "17", "18"]}
            defaultValue={this.state.balise.selected_service.split(";")}
            onChange={(services) => {
              this.setState({ selected_service: services.join(";") });
            }}
          />
          <Divider />
        </div>
      ),
      onOk,
      onCancel() {
        console.log("canceled");
      },
    });
  }

  confirmAddVehicle(vhc_id) {
    const _id = this.props.match.params.id;
    const onOk = () => {
      addVehicle(vhc_id, _id).then((_) => {
        message.success("Véhicule associé à la balise avec succès");
        this.fetchBalise();
      });
    };

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <p>Êtes vous sûr de vouloir ajouter ce véhicule?</p>,
      cancelText: "Annuler",
      okText: "Confirmer",
      onOk,
      onCancel() {},
    });
  }

  handleList() {
    return (
      <>
        <Spacer />
        {this.state.search_vehicules?.map((vehicule, index) => {
          return (
            <div key={"hdlslist" + index}>
              <Divider style={{ margin: 5 }} />
              <Row justify="space-around" style={{ margin: 0 }} align="middle">
                <span style={{ margin: 5, fontSize: 15 }}>
                  {vehicule.identification_vehicule}
                </span>
                <Button
                  size="small"
                  style={{ width: 80 }}
                  onClick={() => this.confirmAddVehicle(vehicule.id)}
                >
                  Ajouter
                </Button>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Spacer height="10px" />
            </div>
          );
        })}
      </>
    );
  }

  handleSearch(searchValue) {
    const userService = middlewareService();

    searchVehicles(searchValue).then(({ vehicules }) => {
      var vehicule_ids = [];
      this.state.vehicules.forEach((vehicule) =>
        vehicule_ids.push(vehicule.id)
      );

      this.setState({
        search_vehicules: vehicules.filter(
          (v) => v.service === `${userService}` && !vehicule_ids.includes(v.id)
        ),
      });
    });
  }

  renderMarker(map, maps) {
    const { Marker } = this.state;

    let marker = new maps.Marker({
      position: Marker.position,
      zoom: Marker.zoom,
      draggable: false,
      dragend: (e) => console.log(e),
      map,
      title: "Helpp-Life",
    });

    // maps.event.addListener(marker, "dragend", (e) => {
    //   var newMarker = this.state.Marker;
    //   newMarker.position = {
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng(),
    //   };
    //   this.setState({
    //     Marker: newMarker,
    //   });
    // });

    return marker;
  }

  render() {
    const userService = middlewareService();
    const isVehicle = middlewareVehicle();

    const {
      balise,
      linked_users,
      isGeo,
      activating_user,
      user_images,
      imageLoaded,
    } = this.state;

    const columns = [
      {
        title: "",
        dataIndex: "id",
        key: "id",
        hidden: true,
      },
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
      },
      {
        title: "Nom",
        dataIndex: "lastname",
        key: "lastname",
      },
      {
        title: "Prénom",
        dataIndex: "firstname",
        key: "firstname",
      },
      {
        title: "Date de naissance",
        dataIndex: "birthdate",
        key: "birthdate",
      },
      {
        title: "Sexe",
        dataIndex: "sex",
        key: "sex",
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],

        render: (_, record) => (
          <>
            <Row gutter={[24, 10]}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => this.props.history.push(`/users/${record.id}`)}
              >
                Détails
              </Button>
            </Row>
          </>
        ),
      },
    ].filter((v) => !v.hidden);

    const columnsVehicule = [
      {
        title: "",
        dataIndex: "id",
        key: "id",
        hidden: true,
      },
      {
        title: "ID Véhicule",
        dataIndex: "identification_vehicule",
        key: "identification_vehicule",
      },
      {
        title: "Postcode",
        dataIndex: "postcode",
        key: "postcode",
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
      },
      {
        title: "Type de véhicule",
        dataIndex: "type_vehicule",
        key: "type_vehicule",
      },
      {
        title: "Caserne de rattachement",
        dataIndex: "sex",
        key: "sex",
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],

        render: (_, record) => (
          <>
            <Row gutter={[24, 10]}>
              {`${userService}` === record.service && (
                <Button
                  danger
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    removeVehicle(record.id, this.props.match.params.id).then(
                      (res) => {
                        console.log(res);
                        this.fetchBalise();
                        message.success("Véhiculé dissocié avec succès");
                      }
                    );
                  }}
                >
                  Dissocier
                </Button>
              )}
            </Row>
          </>
        ),
      },
    ].filter((v) => !v.hidden);

    return (
      <div>
        <CustomLayout selectedKey="warnings">
          <Row justify="start" align="middle" gutter={[6, 12]}>
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              onClick={() => this.props.history.goBack()}
            />
            <h1 style={{ margin: 0, marginLeft: 20 }}>
              Balise {balise?.id_helpp_life}
            </h1>
          </Row>
          <Divider />
          <Spacer />
          <Row gutter={[24, 12]}>
            <Col xs={24} md={24} lg={12} xl={8}>
              <Card
                title={
                  <>
                    <h2 style={{ margin: 0 }}>
                      Détails{" "}
                      <span style={{ fontSize: 13, fontWeight: 400 }}>
                        {isGeo ? "(GEO)" : "(STD)"}
                      </span>
                    </h2>
                  </>
                }
              >
                <p key="id976Gdzae">
                  <h3 style={{ fontWeight: 600, fontSize: 18, margin: 0 }}>
                    Adresse :
                  </h3>
                  <br />
                  <b>Numéro:&nbsp;</b>
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_numero}`}>
                      {balise?.adresse?.adresse_numero}
                    </span>
                  )}
                  <br />
                  <b>Complément numéro:</b>{" "}
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_complement_numero}`}>
                      {balise?.adresse?.adresse_complement_numero}
                    </span>
                  )}
                  <br />
                  <b>Rue:</b>{" "}
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_rue}`}>
                      {balise?.adresse?.adresse_rue}
                    </span>
                  )}
                  <br />
                  <b>Complément de rue:</b>
                  <span key={`${balise?.adresse?.adresse_complement}`}>
                    {balise?.adresse?.adresse_complement}
                  </span>
                  <br />
                  <b>Numéro de porte:</b>{" "}
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_numero_porte}`}>
                      {balise?.adresse?.adresse_numero_porte}
                    </span>
                  )}
                  <br />
                  <b>Etage:&nbsp;</b>
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_etage}`}>
                      {balise?.adresse?.adresse_etage}
                    </span>
                  )}
                  <br />
                </p>
                <p key="zaoeho987dh">
                  <b>Code Postal:</b>{" "}
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_code_postal}`}>
                      {balise?.adresse?.adresse_code_postal}
                    </span>
                  )}
                  <br />
                  <b>Ville:&nbsp;</b>
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_ville}`}>
                      {balise?.adresse?.adresse_ville}
                    </span>
                  )}
                  <br />
                  <b>Pays:</b>{" "}
                  {!isGeo && (
                    <span key={`${balise?.adresse?.adresse_pays}`}>
                      {balise?.adresse?.adresse_pays}
                    </span>
                  )}
                  <br />
                </p>
                <p key="pfjepajf8097">
                  <b>Informations complémentaires:</b>
                  <br />
                  {!isGeo && (
                    <span
                      key={`${balise?.adresse?.adresse_points_remarquables}`}
                    >
                      {balise?.adresse?.adresse_points_remarquables}
                    </span>
                  )}
                </p>
                <Divider />
                <p key="azoizdaj">
                  <b>Informations techniques:</b>
                  <br />
                  {!isGeo && (
                    <span key={`${balise?.id_user_for_who}tech`}>
                      {balise?.info_techniques}
                    </span>
                  )}
                </p>
                <p key="8azndozano">
                  <b>Informations personnelles:</b>
                  <br />
                  {!isGeo && (
                    <span key={`${balise?.id_user_for_who}perso`}>
                      {balise?.info_personnelles}
                    </span>
                  )}
                </p>
                <Divider />
                <p key="dzklfnlz89786">
                  <b>Coordonnées:</b>
                  <br />
                  Lat: {this.state.Marker.position.lat}
                  <br />
                  Lon: {this.state.Marker.position.lng}
                  <br />
                  <br />
                  <br />
                  {this.state.Marker.position?.lat &&
                  this.state.Marker.position?.lng ? (
                    <>
                      <div
                        style={{
                          height: "250px",
                          width: "100%",
                        }}
                      >
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                          defaultCenter={this.state.Marker.position}
                          defaultZoom={13}
                          yesIWantToUseGoogleMapApiInternals={true}
                          onGoogleApiLoaded={({ map, maps }) =>
                            this.renderMarker(map, maps)
                          }
                        ></GoogleMapReact>
                      </div>
                      <Spacer height="15px" />
                      <center>
                        <a
                          href={`https://www.google.com/maps/place/${this.state.Marker.position?.lat},${this.state.Marker.position?.lng}/`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Voir sur Google Maps
                        </a>
                      </center>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
                <Divider />
                <Button
                  type="primary"
                  block
                  onClick={this.disableBalise.bind(this)}
                >
                  <b>Désactiver la balise</b>
                </Button>
              </Card>
            </Col>

            <Col md={24} lg={12} xl={4}>
              <Card title={<h2 style={{ margin: 0 }}>Photos</h2>}>
                {!imageLoaded ? (
                  <center>
                    <Spin />
                  </center>
                ) : (
                  user_images?.map((image, index) => {
                    return (
                      <center>
                        <Image
                          key={`imageopijqsi${index}`}
                          src={image}
                          width={150}
                        />
                        <Spacer />
                      </center>
                    );
                  })
                )}
              </Card>
            </Col>

            <Col md={24} lg={24} xl={12}>
              <Card
                title={<h2 style={{ margin: 0 }}>Utilisateurs en alerte</h2>}
              >
                <Table
                  key={`${activating_user?.id}`}
                  scroll={{ x: 600 }}
                  columns={columns}
                  dataSource={[0].map((_, index) => {
                    return {
                      key: `${activating_user?.id}aocoqh${index}`,
                      id: activating_user?.id,
                      id_helpp_life: activating_user?.id_helpp_life,
                      lastname: activating_user?.nom,
                      firstname: activating_user?.prenom,
                      birthdate: (
                        <Moment format="DD/MM/YYYY">
                          {activating_user?.date_naissance}
                        </Moment>
                      ),
                      sex: activating_user?.sexe > 1 ? "Féminin" : "Masculin",
                    };
                  })}
                />
              </Card>
            </Col>
            <Col md={24} lg={24} xl={24}>
              <Card
                title={
                  <Row justify="space-between" align="middle">
                    <h2 style={{ margin: 0 }}>Utilisateurs associés</h2>
                    <h5>
                      {linked_users?.length}&nbsp;
                      {linked_users?.length > 1
                        ? "Utilisateurs"
                        : "Utilisateur"}
                    </h5>
                  </Row>
                }
              >
                <Table
                  scroll={{ x: 600 }}
                  columns={columns}
                  dataSource={linked_users?.map((user, index) => {
                    return {
                      key: `${index}azeazeza`,
                      id: user?.id,
                      id_helpp_life: user?.id_helpp_life,
                      lastname: user?.nom,
                      firstname: user?.prenom,
                      birthdate: (
                        <Moment format="DD/MM/YYYY">
                          {user?.date_naissance}
                        </Moment>
                      ),
                      sex: user?.sexe > 1 ? "Féminin" : "Masculin",
                    };
                  })}
                />

                <Spacer />
              </Card>
            </Col>

            <Col md={24} lg={24} xl={24}>
              {!isVehicle && (
                <>
                  <Card>
                    <Row justify="space-between" align="middle">
                      <h4
                        style={{ margin: 0, fontWeight: 700, fontSize: 18 }}
                        onClick={() => console.log(this.state.vehicules)}
                      >
                        Services associés: {balise?.selected_service}
                      </h4>
                      <Button onClick={this.handleEditServices.bind(this)}>
                        Modifier
                      </Button>
                    </Row>
                  </Card>
                  <Spacer />
                </>
              )}
              <Card
                style={{ display: isVehicle ? "none" : "block" }}
                title={
                  <Row justify="space-between" align="middle">
                    <h2
                      style={{ margin: 0 }}
                      onClick={() => console.log(this.state.vehicules)}
                    >
                      Véhicules associés
                    </h2>
                    <h5>
                      {this.state.vehicules?.length}&nbsp;
                      {this.state.vehicules?.length > 1
                        ? "Véhicules"
                        : "Véhicule"}
                    </h5>
                  </Row>
                }
              >
                <Table
                  scroll={{ x: 600 }}
                  columns={columnsVehicule}
                  dataSource={this.state.vehicules?.map((vehicule, index) => {
                    const {
                      caserne_rattachement,
                      identification_vehicule,
                      postcode,
                      service,
                      type_vehicule,
                      id,
                    } = vehicule;

                    return {
                      key: `${index}ldjdqfdo`,
                      caserne_rattachement,
                      identification_vehicule,
                      postcode,
                      service,
                      type_vehicule,
                      id,
                    };
                  })}
                />

                <Spacer />

                <div>
                  <Divider />
                  <Row justify="space-between">
                    <Col span={3}>
                      <Button
                        onClick={() =>
                          this.setState({
                            showSearchVehicles: !this.state.showSearchVehicles,
                          })
                        }
                        danger={this.state.showSearchVehicles}
                      >
                        {!this.state.showSearchVehicles
                          ? "Ajouter un véhicule"
                          : "Annuler"}
                      </Button>
                    </Col>
                    {this.state.showSearchVehicles && (
                      <Col span={21}>
                        <Input.Search
                          onInput={(e) => this.handleSearch(e.target.value)}
                          placeholder="Rechercher un véhicule"
                        />
                      </Col>
                    )}
                  </Row>
                  {this.state.showSearchVehicles && this.handleList()}
                  <Divider style={{ maginBottom: 0 }} />
                </div>
              </Card>
            </Col>
          </Row>
        </CustomLayout>
      </div>
    );
  }
}

export default DetailBalise;
