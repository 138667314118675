import axios from "axios";
import { API_URL } from "../config";
import { catchError } from "./response";

const getApiRequestService = () => {
  return axios.create({
    baseURL: `${API_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

export const getBalisesVehicles = async (balise_id: string) => {
  try {
    const res = await getApiRequestService().get(
      `/balises/${balise_id}/vehicules_associes`
    );

    return res.data;
  } catch (error) {
    console.log("GET_BALISES_VEHICLES", error);

    return catchError(error);
  }
};

export const getVehiclesBalises = async (vhc_id: string) => {
  try {
    const res = await getApiRequestService().get(
      `/balises/enAlerte_vehicule/${vhc_id}`
    );

    return res.data;
  } catch (error) {
    console.log("GET_VEHICLES_BALISES", error);

    return catchError(error);
  }
};

export const searchVehicles = async (searchValue: string) => {
  try {
    const res = await getApiRequestService().get(
      `/vehicules/search?value=${searchValue}`
    );

    return res.data;
  } catch (error) {
    console.log("SEARCH_VEHICLES", error);

    return catchError(error);
  }
};

export const addVehicle = async (vhc_id: string, balise_id: string) => {
  try {
    const res = await getApiRequestService().post(
      `/vehicules/${vhc_id}/associer_balise/${balise_id}`
    );

    return res.data;
  } catch (error) {
    console.log("ADD_VEHICLE", error);

    return catchError(error);
  }
};

export const removeVehicle = async (vhc_id: string, balise_id: string) => {
  try {
    const res = await getApiRequestService().delete(
      `/vehicules/${vhc_id}/dissocier_balise`
    );

    return res.data;
  } catch (error) {
    console.log("ADD_VEHICLE", error);

    return catchError(error);
  }
};
