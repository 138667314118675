import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";
import HomePage from "./views/homePage/HomePage";
import UsersPage from "./views/usersPage/UsersPage";
import SplashPage from "./views/static/SplashPage";
import ErrorPage from "./views/static/ErrorPage";
import LoginPage from "./views/authPages/loginPage";
import DetailBalise from "./views/homePage/DetailBalise";
import DetailUser from "./views/usersPage/DetailUser";
import ResetPassword from "./views/authPages/resetPassword";
import QRPage from "./views/static/QRPage";
import SelectVehicle from "./views/static/selectVehicle";

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route
              exact
              path="/warnings"
              render={(props) => <HomePage {...props} />}
            />

            <Route
              exact
              path="/vehicles/select"
              render={(props) => <SelectVehicle {...props} />}
            />

            <Route
              exact
              path="/warnings/detail/:id"
              render={(props) => <DetailBalise {...props} />}
            />

            <Route
              exact
              path="/users"
              render={(props) => <UsersPage {...props} />}
            />
            <Route
              exact
              path="/users/:id"
              render={(props) => <DetailUser {...props} />}
            />

            <Route
              exact
              path="/"
              render={(props) => <SplashPage {...props} />}
            />
            <Route
              exact
              path="/login"
              render={(props) => <LoginPage {...props} />}
            />
            <Route
              exact
              path="/reset-password"
              render={(props) => <ResetPassword {...props} />}
            />

            <Route
              exact
              path="/qrcode/:id"
              render={(props) => <QRPage {...props} />}
            />

            <Route render={(props) => <ErrorPage {...props} />} />
          </Switch>
        </Router>
      </>
    );
  }
}
export default App;
