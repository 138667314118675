import axios from "axios";
import { API_URL } from "../config";
import { catchError } from "./response";

const getApiRequestService = () => {
  return axios.create({
    baseURL: `${API_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

export const getAllBalises = async () => {
  try {
    const res = await getApiRequestService().get(`/balises/enAlerte`);

    return res.data;
  } catch (error) {
    console.log("GET_ALL_BALISES", error);

    return catchError(error);
  }
};

export const getBalise = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/balises/${id}`);

    return res.data;
  } catch (error) {
    console.log("GET_BALISE", error);

    return catchError(error);
  }
};

export const updateBalise = async (id: any, body: any) => {
  try {
    const res = await getApiRequestService().put(`/balises/${id}`, body);

    return res.data;
  } catch (error) {
    console.log("UPDATE_BALISE", error);

    return catchError(error);
  }
};

export const getBaliseUser = async (id: any) => {
  try {
    const res = await getApiRequestService().get(`/balises/${id}/users`);

    return res.data;
  } catch (error) {
    console.log("GET_BALISE_USER", error);

    return catchError(error);
  }
};

export const setBaliseAlert = async (id: any, data: any) => {
  try {
    const res = await getApiRequestService().post(
      `/balises/${id}/mettre-en-alerte`,
      data
    );

    return res.data;
  } catch (error) {
    console.log("SET_BALISE_ALERT", error);

    return catchError(error);
  }
};

export const setBaliseGeoAlert = async (body: any) => {
  try {
    const res = await getApiRequestService().post("balises/geostart", body);

    return res.data;
  } catch (error) {
    console.log("SET_BALISE_GEO_ALERT", error);

    return catchError(error);
  }
};

export const unsetBaliseGeoAlert = async (id: string) => {
  try {
    const res = await getApiRequestService().post(`balises/geostop/${id}`);
    console.log("unset_balise_geo");
    return res.data;
  } catch (error) {
    console.log("SET_BALISE_GEO_ALERT", error);

    return catchError(error);
  }
};

export const unsetBaliseAlert = async (id: any) => {
  try {
    const res = await getApiRequestService().post(
      `/balises/${id}/supprimer-en-alerte`
    );

    return res.data;
  } catch (error) {
    console.log("UNSET_BALISE_ALERT", error);

    return catchError(error);
  }
};
