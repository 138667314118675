import React, { Component } from "react";
import {
  Divider,
  Tag,
  Table,
  Button,
  Row,
  Input,
  Tooltip,
  Modal,
  message, Select,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import {
  middlewareService,
  middlewareVehicle,
  Spacer,
} from "../../components/global";
import {
  getAllBalises,
  setBaliseGeoAlert,
  unsetBaliseAlert,
  unsetBaliseGeoAlert,
} from "../../services/balises.services";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { searchUsers, getMedicalFolder } from "../../services/users.services";
import Fuse from "fuse.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getVehiclesBalises } from "../../services/vehicules.services";

const { Search, TextArea } = Input;
const { confirm } = Modal;

class HomePage extends Component {
  state = {
    searchValue: "",
    balises: [],
    const_balises: [],
    showModal: false,
    is_sourd_muet: [],
    searchAdress: "",
    newAlert: {},
    context_pro: false,
  };

  fetchBalises = async () => {
    // Getting auth token
    var accessToken = localStorage.getItem("accessToken");
    // Checking if there is a token before requesting
    if (accessToken !== null && accessToken !== undefined) {
      getAllBalises().then((res) => {
        console.log("all balises infos", res.balises);
        this.setState(
          { balises: res.balises, const_balises: res.balises },
          () => {
            this.formatBalise();
          }
        );
      });
    }
  };

  fetchBalisesVehicule = async () => {
    var accessToken = localStorage.getItem("accessToken");

    if (accessToken)
      getVehiclesBalises(localStorage.getItem("_vhc")).then((res) => {
        console.log("all balises vhc", res);

        this.setState({ balises: res.balises, const_balises: res.balises });
      });
  };

  componentDidMount() {
    // Type account
    // const _tacc = localStorage.getItem("_tacc");
    // console.log(sha256("3") === _tacc);

    // Fetch all balises
    const isVehicle = middlewareVehicle();
    if (isVehicle) this.fetchBalisesVehicule();
    else this.fetchBalises();

    this.intervalBalises();
  }

  handleSelect = (address) => {
    this.setState({ searchAdress: address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        // console.log("Success", latLng);
        var newAlert = this.state.newAlert;
        newAlert["lon"] = latLng.lng;
        newAlert["lat"] = latLng.lat;
        this.setState({
          newAlert,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  intervalBalises = (delay = 10000) => {
    const isVehicle = middlewareVehicle();

    this.intervalID = setInterval(() => {
      if (isVehicle) this.fetchBalisesVehicule();
      else this.fetchBalises();
    }, delay);
  };

  onSearch(value) {
    var const_balises = this.state.const_balises;

    if (value.length > 0) {
      clearInterval(this.intervalID);

      const fuse = new Fuse(const_balises, {
        keys: ["id_helpp_life"],
        includeScore: true,
        findAllMatches: true,
        threshold: 0.2,
      });

      var results = fuse.search(value);

      console.log(results);

      var balisesResults = [];
      if (results.length) {
        results.forEach((result) => {
          balisesResults.push(result.item);
        });
        this.setState({ balises: balisesResults }, () => this.formatBalise());
      } else this.setState({ balises: [] });
    } else {
      this.setState({ balises: this.state.const_balises });
      this.intervalBalises();
    }
  }

  disableBalise = async (balise_id) => {
    const isVehicle = middlewareVehicle();
    const onOk = () => {
      var tmp_balise;

      this.state.balises
        ?.map((t) => {
          tmp_balise = t;
          return t;
        })
        .filter((v) => v.id === balise_id);

      if (tmp_balise?.appkey?.length)
        unsetBaliseAlert(balise_id).then((res) => {
          console.log("physic", res);

          if (!res.error) {
            message.success("Balise désactivée avec succès");
            if (isVehicle) this.fetchBalisesVehicule();
            else this.fetchBalises();
          }
        });
      else {
        unsetBaliseGeoAlert(balise_id).then((res) => {
          console.log("geoloc", res);

          if (!res.error) {
            message.success("Balise désactivée avec succès");
            if (isVehicle) {
              this.fetchBalisesVehicule();
              this.fetchBalisesVehicule();
            } else {
              this.fetchBalises();
              this.fetchBalises();
            }
          }
        });
      }
    };

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: <p>Êtes vous sûr de vouloir désactiver la balise?</p>,
      onOk,
      onCancel() {
        console.log("canceled");
      },
    });
  };

  handleOk() {
    var newAlert = this.state.newAlert;
    newAlert.selected_service = `${middlewareService()}`;
    newAlert.context_pro = this.state.context_pro;
    console.log(this.state.newAlert);
    setBaliseGeoAlert(newAlert).then((res) => {
      console.log(res);
      this.fetchBalises();
      this.setState({ showModal: false });
      message.success("Balise sans alerte déclenchée");
    });
  }

  handleCancel() {
    this.setState({ showModal: false });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  formatBalise = async () => {
    var baliseArr = [];
    for (const balise of this.state.balises) {
      const {
        adresse_numero,
        adresse_rue,
        adresse_code_postal,
        adresse_ville,
        adresse_pays,
      } = balise?.adresse;
      const filtered_address =
        adresse_numero +
        " " +
        adresse_rue +
        " " +
        adresse_code_postal +
        " " +
        adresse_ville +
        " " +
        adresse_pays;

      var tags = [];

      if (balise?.proximity_of_agressor) tags.push("agresseur");
      await getMedicalFolder(balise?.id_user_for_who).then(
        // eslint-disable-next-line no-loop-func
        (res) => {
          if (res?.dossier_medical?.sourd_muet) tags.push("sourd_muet");
        }
      );

      baliseArr.push({
        key: balise?.id,
        balise: balise,
        id: balise?.id,
        id_helpp_life: balise?.id_helpp_life,
        id_user_for_who: balise?.id_user_for_who,
        context_pro: this.state.context_pro,
        address:
          balise?.appkey.length > 0 ? filtered_address : balise?.addresse,
        tags,
      });
    }

    this.setState({ baliseArr });
  };

  render() {
    const { showModal } = this.state;

    const isVehicle = middlewareVehicle();

    const userService = middlewareService();

    const columns = [
      {
        title: "",
        dataIndex: "id",
        key: "id",
        hidden: true,
      },
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
      },
      {
        title: "Adresse",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],

        render: (_, record) => (
          <>
            <Row gutter={[24, 10]}>
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                onClick={() =>
                  this.props.history.push(`/warnings/detail/${record.id}`)
                }
              >
                Détails
              </Button>
              <Button danger onClick={() => this.disableBalise(record.id)}>
                Désactiver
              </Button>
            </Row>
          </>
        ),
      },
      {
        title: "Statut",
        dataIndex: "tags",
        key: "tags",
        render: (tags, record) => {
          return (
            <>
              {tags?.map((tag) => {
                let color = "rgba(0,0,0,0)";
                if (tag === "sourd_muet") {
                  color = "#108ee9";
                } else if (tag === "agresseur") {
                  color = "#e74c3c";
                }
                return (
                  <Tooltip title={tag.toUpperCase()} key={tag}>
                    <Tag color={color} key={tag}>
                      &nbsp;
                    </Tag>
                  </Tooltip>
                );
              })}
            </>
          );
        },
      },
    ].filter((v) => !v.hidden);

    return (
      <div>
        <CustomLayout selectedKey="warnings">
          <Row justify="space-between" align="middle" gutter={[6, 12]}>
            <h1 style={{ margin: 0 }}>Alertes en cours</h1>
            <span>Service: {userService}</span>
            {!isVehicle && (
              <Button
                type="primary"
                onClick={() => this.setState({ showModal: true })}
              >
                Déclencher une alerte sans balise
              </Button>
            )}
          </Row>
          <Divider />
          <Row justify="space-between" align="middle">
            <Search
              style={{ width: "auto" }}
              placeholder="Rechercher"
              onInput={(e) => this.onSearch(e.target.value)}
              enterButton
            />
            <h4 style={{ margin: 0, fontWeight: 800 }}>
              {this.state.baliseArr?.length ?? 0} balise
              {this.state.baliseArr?.length && "s"}
            </h4>
          </Row>
          <Spacer />

          <Table
            columns={columns}
            scroll={{ x: 800 }}
            dataSource={this.state.baliseArr}
          />
        </CustomLayout>
        <Modal
          title="Déclencher une alerte sans balise"
          visible={showModal}
          onOk={this.handleOk.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          okText="Déclencher"
          cancelText="Annuler"
        >
          <div>
            <h4>Contexte</h4>
            <Select
                placeholder="Contexte"
                style={{ width: "100%", marginBottom: 12 }}
                defaultValue={false}
                onChange={(v) => {
                  let na = this.state.newAlert;
                  na.context_pro = this.state.context_pro;
                  this.setState({context_pro: v, newAlert: na});
                }}
            >
              <Select.Option value={false}>Vie Privée</Select.Option>
              <Select.Option value={true}>Professionel</Select.Option>
            </Select>
            <h4>Utilisateur</h4>
            <Search
              value={this.state.selectedUser}
              placeholder="Rechercher un utilisateur"
              onInput={(e) => {
                this.setState({ selectedUser: e.target.value });
                if (e.target.value?.length)
                  searchUsers(e.target.value).then((res) =>
                    this.setState({
                      searchUsers: res.users,
                    })
                  );
              }}
            />
            <Spacer />
            {this.state.searchUsers?.map((user) => (
              <p>
                <Button
                  type="link"
                  onClick={() => {
                    var newAlert = {};
                    newAlert.id_user_for_who = user.id;
                    this.setState({
                      newAlert,
                      searchUsers: [],
                      selectedUser: user.nom + " " + user.prenom,
                    });
                  }}
                >
                  {user.nom + " " + user.prenom}
                </Button>
              </p>
            ))}
            <Spacer height="10px" />
            <h4>Adresse</h4>
            <PlacesAutocomplete
              value={this.state.searchAdress}
              onChange={(searchAdress) => this.setState({ searchAdress })}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Search
                    value={this.state.searchAdress}
                    placeholder="Rechercher une adresse"
                    {...getInputProps({
                      placeholder: "Rechercher une adresse",
                    })}
                  />
                  {loading ? <div>Chargement...</div> : null}

                  {suggestions.map((suggestion) => {
                    return (
                      <Button
                        type="link"
                        {...getSuggestionItemProps(suggestion)}
                      >
                        {suggestion.description}
                      </Button>
                    );
                  })}
                </div>
              )}
            </PlacesAutocomplete>
            <Spacer height="10px" />
            <h4>Informations complémentaires</h4>
            <TextArea rows={4} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default HomePage;
