import React, { Component } from "react";
import styled from "styled-components";
import BrandLogo from "../../assets/images/logo.png";

class ErrorPage extends Component {
  render() {
    return (
      <>
        <SplashContainer style={{ height: window.innerHeight }}>
          <LogoWrapper>
            <center>
              <img src={BrandLogo} alt=" " />
            </center>
          </LogoWrapper>
          <TextWrapper>
            <h1>Error 404</h1>
            <h3>Page not found</h3>

            <h5 onClick={() => this.props.history.goBack()}>Go back</h5>
          </TextWrapper>
        </SplashContainer>
      </>
    );
  }
}

export default ErrorPage;

const SplashContainer = styled.div`
  background: #000;
  padding: 20px;
  position: relative;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%);
  img {
    height: 50px;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    color: white;
    font-weight: 800;
    font-size: 72px;
    margin: 0;
    text-align: center;
    width: 100%;
  }
  h3 {
    color: white;
    margin: 0;
    text-align: center;
    font-size: 36px;
  }
  h5 {
    color: white;
    margin: 0;
    text-align: center;
    text-decoration: underline;
    margin-top: 30px;
    cursor: pointer;
    font-size: 18px;
  }
`;
