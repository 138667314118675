import React, { Component } from "react";
import styled from "styled-components";
import { Button, Card, Input, Row } from "antd";
import Logo from "../../assets/images/logo.png";
import { searchVehicles } from "../../services/vehicules.services";
import { middlewareService, Spacer } from "../../components/global";

class SelectVehicle extends Component {
  state = {
    vehicule: "",
    searchValue: " ",
    vehicules: [],
  };

  componentDidMount() {
    if (!localStorage.getItem("accessToken"))
      this.props.history.replace("/login");
    if (localStorage.getItem("_vhc")) this.props.history.replace("/warnings");
  }

  handleSelect(vehicule) {
    localStorage.setItem("_vhc", vehicule);
    this.props.history.push("/warnings");
  }

  handleSearch(searchValue) {
    const userService = middlewareService();

    this.setState({ searchValue }, () => {
      searchVehicles(this.state.searchValue).then(({ vehicules }) => {
        this.setState({
          vehicules: vehicules.filter((v) => v.service === `${userService}`),
        });
      });
    });
  }

  render() {
    const userService = middlewareService();
    return (
      <>
        <LoginWrapper>
          <CardWrapper>
            <Card
              title={
                <div>
                  <center>
                    <img src={Logo} alt="" style={{ height: 60, margin: 0 }} />
                  </center>
                  <br />
                  <h1
                    style={{ margin: 0 }}
                    onClick={() => console.log(this.state.vehicules)}
                  >
                    Sélection du véhicule
                  </h1>
                  <h5>
                    Service: {userService} • Recherchez un véhicule à l'aide de
                    la barre de recherche
                  </h5>
                </div>
              }
              bordered={false}
            >
              <div style={{ padding: "10px 5% 10%" }}>
                {this.state.vehicule}
                <Row gutter={[12, 24]} justify="center">
                  <Input.Search
                    onInput={(e) => this.handleSearch(e.target.value)}
                    placeholder="Rechercher un véhicule"
                    size="large"
                  />
                </Row>
                <Spacer />

                <div
                  style={{
                    height: 145,
                    overflowY: "scroll",
                    border: "1px solid #eee",
                    margin: 0,
                    paddingBottom: 0,
                  }}
                >
                  {!this.state.vehicules.length &&
                    this.state.searchValue.length > 1 && (
                      <h5 style={{ marginTop: 50 }}>
                        Aucun véhicule trouvé pour ce service
                      </h5>
                    )}
                  {this.state.vehicules?.map((vehicule) => {
                    return (
                      <Row style={{ marginBottom: 0 }}>
                        <Button
                          style={{ marginBottom: 0 }}
                          type="link"
                          onClick={() =>
                            this.handleSelect(vehicule.identification_vehicule)
                          }
                        >
                          <span style={{ fontSize: 16 }}>
                            {vehicule?.identification_vehicule} (
                            {vehicule?.service})
                          </span>
                        </Button>
                      </Row>
                    );
                  })}
                </div>
              </div>
            </Card>
          </CardWrapper>
        </LoginWrapper>
      </>
    );
  }
}

export default SelectVehicle;

const LoginWrapper = styled.div`
  background: #777DA7;
  position: relative;
  padding: 20px;
  min-height: ${window.innerHeight}px;
  max-height: 100%;
  color: white;

  h1 {
      text-align: center;
      font-weight 800;
      margin: 0
  }

  h5 {
    text-align: center;
    margin: 0

  }
`;

const CardWrapper = styled.div`
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
