import React, { Component } from "react";
import styled from "styled-components";
import { Button, Card, Divider, Input, message, Row } from "antd";
import { Link } from "react-router-dom";
import { Spacer } from "../../components/global";
import { resetPassword } from "../../services/auth.services";
import Logo from "../../assets/images/logo.png";

class ResetPassword extends Component {
  state = {
    email: "",
  };

  resetPwd() {
    resetPassword(this.state.email).then((res) => {
      if (!res?.status)
        message.success(
          "Un email de réinitialisation de mot de passe a bien été envoyé",
          2,
          () => this.props.history.push("/login")
        );
    });
  }

  render() {
    return (
      <>
        <LoginWrapper>
          <CardWrapper>
            <Card
              title={
                <div>
                  <center>
                    {" "}
                    <img src={Logo} alt="" style={{ height: 60, margin: 0 }} />
                  </center>
                  <br />
                  <h1 style={{ margin: 0 }}>Mot de passe oublié</h1>
                </div>
              }
              bordered={false}
            >
              <div style={{ padding: "0 10%" }}>
                <Row gutter={[12, 24]} justify="center">
                  {this.state.email}
                  <Input
                    placeholder="Email"
                    size="large"
                    type="email"
                    onInput={(e) => this.setState({ email: e.target.value })}
                  />
                  <Button
                    size="large"
                    type="primary"
                    block
                    onClick={this.resetPwd.bind(this)}
                  >
                    Envoyer
                  </Button>
                  <Divider />
                  <Link to="/login">Connexion</Link>
                  <Spacer height="5vh" />
                </Row>
              </div>
            </Card>
          </CardWrapper>
        </LoginWrapper>
      </>
    );
  }
}

export default ResetPassword;

const LoginWrapper = styled.div`
  background: #000;
  position: relative;
  padding: 20px;
  min-height: ${window.innerHeight}px;
  max-height: 100%;
  color: white;

  h1 {
      text-align: center;
      font-weight 800;
      margin: 0
  }
`;

const CardWrapper = styled.div`
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
