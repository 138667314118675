import { sha256 } from "js-sha256";
import styled from "styled-components";

export const Spacer = styled.div`
  height: ${(props) => props.height ?? "25px"};
  opacity: 0;
`;

export const middlewareVehicle = () => {
  switch (localStorage.getItem("_tacc")) {
    case sha256("32"):
      return true;

    case sha256("42"):
      return true;

    case sha256("52"):
      return true;

    default:
      return false;
  }
};

export const middlewareService = () => {
  switch (localStorage.getItem("_tacc")) {
    case sha256("3"):
      return 18;

    case sha256("31"):
      return 18;

    case sha256("32"):
      return 18;

    case sha256("41"):
      return 15;

    case sha256("42"):
      return 15;

    case sha256("51"):
      return 17;

    case sha256("52"):
      return 17;

    default:
      break;
  }
};

export const renderAddress = (adresse) => {
  const {
    adresse_numero,
    adresse_rue,
    adresse_code_postal,
    adresse_ville,
    adresse_pays,
  } = adresse;

  return (
    adresse_numero +
    " " +
    adresse_rue +
    " " +
    adresse_code_postal +
    " " +
    adresse_ville +
    " " +
    adresse_pays
  );
};
