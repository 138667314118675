import React, { Component } from "react";
import { Divider, Table, Button, Row, Input } from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer } from "../../components/global";
import { searchUsers } from "../../services/users.services";
import Moment from "react-moment";

const { Search } = Input;

class UsersPage extends Component {
  state = {
    searchValue: "",
  };

  onSearch(value) {
    if (value.length > 1) {
      this.setState({ searchValue: value });
      searchUsers(value).then((res) => {
        console.log("search users", res.users);
        this.setState({
          data: res.users,
        });
      });
    } else {
      this.setState({ data: [] });
    }
  }

  render() {
    const columns = [
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
      },
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
      {
        title: "Prénom",
        dataIndex: "prenom",
        key: "prenom",
      },
      {
        title: "Date de naissance",
        dataIndex: "date_naissance",
        key: "date_naissance",
        render: (value) => <Moment format="DD/MM/YYYY">{value}</Moment>,
      },
      {
        title: "Sexe",
        dataIndex: "sexe",
        key: "sexe",
        render: (value) => (value > 1 ? "Féminin" : "Masculin"),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],

        render: (_, record) => (
          <>
            <Row gutter={[24, 10]}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => this.props.history.push(`/users/${record.id}`)}
              >
                Détails
              </Button>
            </Row>
          </>
        ),
      },
    ];

    const { searchValue, data } = this.state;
    return (
      <div>
        <CustomLayout selectedKey="users">
          <Row justify="space-between" align="middle" gutter={[6, 12]}>
            <h1 style={{ margin: 0 }}>Utilisateurs</h1>
            {/* <Link to="/qrcode/Tutu">Voir page QR Code</Link> */}
          </Row>
          <Divider />
          <Row justify="space-between" align="middle">
            <Search
              style={{ width: "auto" }}
              placeholder="Rechercher"
              onInput={(e) => this.onSearch(e.target.value)}
              enterButton
            />
            {searchValue.length > 1 && (
              <h4 style={{ margin: 0, fontWeight: 800 }}>
                {data?.length ?? 0} Utilisateur{data?.length > 1 && "s"}
              </h4>
            )}
          </Row>
          <Spacer />
          <Table columns={columns} dataSource={data} scroll={{ x: 800 }} />
        </CustomLayout>
      </div>
    );
  }
}

export default UsersPage;
